export const loadGoogleSignInSdk = () => {
  return new Promise((resolve, reject) => {
    const scriptName = "gsi-client";

    var js,
      fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById(scriptName)) {
      return resolve();
    } else {
      js = document.createElement("script");
      js.scriptName = scriptName;
      js.src = "//accounts.google.com/gsi/client";
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = function() {
        resolve();
      };
    }
  });
};
