<template>
  <div class="login-content login-oAEBqgEHZ">
    <div v-show="showForgotPassword" class="forgot-password">
      <h3 class="text-center">
        <span v-if="!showResetPasswordBox">Forgotten your password?</span>
        <span v-else>Reset your password</span>
      </h3>
      <el-form
        v-show="!showResetPasswordBox"
        class="form-forgot-password"
        :model="formFieldsForgot"
        :rules="rulesForgot"
        ref="form-forgot-password"
        @submit.native.prevent
      >
        <el-form-item prop="email" required>
          <el-input
            class="mb2"
            type="email"
            placeholder="Email"
            v-model="formFieldsForgot.email"
          ></el-input>
        </el-form-item>

        <el-button
          @click="submitForgotPasswordForm('form-forgot-password')"
          native-type="submit"
          class="submit mt3"
          type="success"
          :icon="$wait.is('resettingPassword') ? 'el-icon-loading' : ''"
          >Continue</el-button
        >
        <p class="login-footer">
          <el-link
            :underline="false"
            @click="toggleForgotPassBox(false)"
            type="primary"
            >Return to Login</el-link
          >
        </p>
      </el-form>

      <el-form
        class="form-reset-password"
        v-show="showResetPasswordBox"
        ref="form-reset-password"
        @submit.native.prevent
      >
        <el-form-item prop="code" label="Enter the code you received in e-mail">
          <CodeInput
            :loading="false"
            class="input"
            type="number"
            :fieldWidth="75"
            :fieldHeight="40"
            :autoFocus="true"
            @change="onCodeChange"
            :fields="4"
            v-model="formFieldsReset.code"
          />
        </el-form-item>

        <el-form-item>
          <el-input
            type="password"
            placeholder="Set a new password"
            v-model="formFieldsReset.password"
          ></el-input>
        </el-form-item>

        <el-button
          @click="submitResetPasswordForm('form-reset-password')"
          native-type="submit"
          class="submit mt3"
          type="success"
          :icon="$wait.is('settingPassword') ? 'el-icon-loading' : ''"
          >Reset Password</el-button
        >
      </el-form>

      <div
        v-show="showResetPasswordBox"
        class="signin-footer d-flex justify-space-between mt3 pl3 pr3"
      >
        <el-link
          :underline="false"
          @click="toggleResetPasswordBox(false)"
          class="ml1"
          type="primary"
          >Change email</el-link
        >

        <el-link
          :underline="false"
          @click="resendCode"
          target="_blank"
          class="mr1"
          type="primary"
          >Didn't received the code?</el-link
        >
      </div>
    </div>
    <div v-show="!showForgotPassword" class="login-signup">
      <h3 class="text-center">
        <span v-if="isSigningUp">Create an Account</span>
        <span v-else>Login</span>
      </h3>

      <div class="sign-in-google d-flex justify-center">
        <div
          id="g_id_onload"
          :data-client_id="GOOGLE_CLIENT_ID"
          data-callback="handleGoogleSignIn"
        ></div>
        <div class="g_id_signin" data-type="standard"></div>
      </div>

      <div>
        <el-divider v-if="isSigningUp" content-position="center"
          >OR SIGN UP WITH EMAIL</el-divider
        >
        <el-divider v-else content-position="center">OR</el-divider>
      </div>

      <div>
        <el-form
          class="form-signup"
          v-show="isSigningUp"
          :model="formFieldsSignUp"
          :rules="rulesSignUp"
          ref="formSignUp"
          @submit.native.prevent
        >
          <el-form-item prop="name" required>
            <el-input
              placeholder="Name"
              v-model="formFieldsSignUp.name"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email" required>
            <el-input
              type="email"
              placeholder="Email"
              v-model="formFieldsSignUp.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" required>
            <el-input
              type="password"
              placeholder="Password"
              v-model="formFieldsSignUp.password"
            ></el-input>
          </el-form-item>
          <el-button
            native-type="submit"
            class="submit"
            @click="submitForm('formSignUp')"
            type="success"
            :icon="$wait.is('loginSubmitting') ? 'el-icon-loading' : ''"
            >Create Account</el-button
          >
          <p class="login-footer">
            Already have an account?
            <el-link
              :underline="false"
              @click="toggleSignUpBox(false)"
              type="primary"
              >Log In</el-link
            >
          </p>
        </el-form>

        <el-form
          class="form-signin"
          v-show="!isSigningUp"
          :model="formFieldsSignIn"
          :rules="rulesSignIn"
          ref="formSignIn"
          @submit.native.prevent
        >
          <el-form-item prop="email" required>
            <el-input
              type="email"
              placeholder="Email"
              v-model="formFieldsSignIn.email"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" required>
            <el-input
              type="password"
              placeholder="Password"
              v-model="formFieldsSignIn.password"
            ></el-input>
          </el-form-item>

          <el-button
            class="submit"
            native-type="submit"
            @click="submitForm('formSignIn')"
            type="success"
            :icon="$wait.is('loginSubmitting') ? 'el-icon-loading' : ''"
            >Login</el-button
          >

          <div class="signin-footer d-flex justify-space-between mt3 pl3 pr3">
            <el-link
              :underline="false"
              @click="toggleSignUpBox(true)"
              class="ml1"
              type="primary"
              >Sign Up</el-link
            >

            <el-link
              :underline="false"
              @click="toggleForgotPassBox(true)"
              target="_blank"
              class="mr1"
              type="primary"
              >Forgot Password?</el-link
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ObjectId from "@/libs/object-id";
import { clone, parseJwt } from "@/utils";
import { loadGoogleSignInSdk } from "@/libs/social-logins";
import CodeInput from "vue-verification-code-input";
import config from "@/config";

export default {
  props: {},
  components: {
    CodeInput,
  },
  data: function() {
    return {
      isSigningUp: this.$route.query.signup === "1" ? true : false,
      showForgotPassword: false,
      showResetPasswordBox: false,
      GOOGLE_CLIENT_ID: config.GOOGLE_CLIENT_ID,
      formFieldsSignIn: {
        email: "",
        password: "",
      },
      formFieldsForgot: {
        email: "",
      },
      formFieldsReset: {
        password: "",
        code: "",
      },
      formFieldsSignUp: {
        name: "",
        email: "",
        password: "",
      },
      rulesSignIn: {
        email: [
          {
            required: true,
            message: "Please enter your e-mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter a valid e-mail",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
      },
      rulesForgot: {
        email: [
          {
            required: true,
            message: "Please enter your e-mail",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter a valid e-mail",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapActions("authModule", [
      "authenticate",
      "getUserInfo",
      "loginWP",
      "registerWP",
      "resetPassword",
      "setPassword",
    ]),
    ...mapGetters("authModule", ["isAuthenticated"]),
    rulesSignUp: function() {
      let newRules = clone(this.rulesSignIn);
      newRules.password.push({
        min: 6,
        message: "Password should be at least 6 characters",
        trigger: "blur",
      });
      newRules.name = [
        {
          required: true,
          message: "Please enter your name",
          trigger: "blur",
        },
      ];
      return newRules;
    },
  },
  mounted: function() {
    window.handleGoogleSignIn = this.handleGoogleSignIn;

    loadGoogleSignInSdk().then(() => {
      google.accounts.id.initialize({
        client_id: config.GOOGLE_CLIENT_ID,
        callback: this.handleGoogleSignIn,
      });
    });
  },
  methods: {
    toggleSignUpBox(val) {
      this.isSigningUp = val;
    },
    toggleForgotPassBox(val) {
      this.isSigningUp = false;
      this.showForgotPassword = val;
      this.showResetPasswordBox = false; // reset password box is inside forgot password
    },
    toggleResetPasswordBox(val) {
      this.showResetPasswordBox = val;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$wait.start("loginSubmitting");

          if (formName === "formSignUp") {
            this.handleWordPressRegister();
          } else {
            this.handleWordPressSignIn();
          }
        } else {
          this.$wait.end("loginSubmitting");

          this.$message({
            type: "error",
            message: "Please check the fields before proceeding",
          });
          return false;
        }
      });
    },
    async authUserFromSocial(credentials, social_account_type, token) {
      try {
        this.$wait.start("loginSubmitting");

        const res = await this.$store.dispatch("authModule/authenticate", {
          email: credentials.email,
          display_name: credentials.name ? credentials.name : null,
          password: credentials.password,
          connected_social_account_type: social_account_type,
          token: token,
        });

        if (res.data) {
          const userI = await this.$store.dispatch(
            "authModule/getUserInfo",
            res.data._id
          );
          return res;
        }
        this.$wait.end("loginSubmitting");

        return res;
      } catch (e) {
        this.$wait.end("loginSubmitting");

        return e;
      }
    },
    async handleGoogleSignIn(credentialResponse) {
      try {
        const { credential } = credentialResponse;

        if (!credential) {
          console.error(
            "There was an error logging in with your google account. Please contact the administrator"
          );
          return null;
        }

        const profile = parseJwt(credential);

        await this.authUserFromSocial(profile, "GOOGLE", {
          access_token: credential,
        });

        this.$emit("afterLogin");
      } catch (error) {
        return null;
      }
    },
    async handleWordPressRegister() {
      try {
        const wpUser = await this.$store.dispatch("authModule/registerWP", {
          username: ObjectId(),
          email: this.formFieldsSignUp.email,
          password: this.formFieldsSignUp.password,
        });

        if (wpUser.status === 200 && wpUser.data) {
          await this.authUserFromSocial(
            {
              email: this.formFieldsSignUp.email,
              name: this.formFieldsSignUp.name,
              password: this.formFieldsSignUp.password,
            },
            "WORDPRESS"
          );

          this.$wait.end("loginSubmitting");

          this.$emit("afterLogin");
        } else {
          this.$wait.end("loginSubmitting");

          this.$message({
            type: "error",
            message:
              wpUser.data.message.indexOf("already exists") > -1
                ? "Account with email already exists. Please login/reset password"
                : wpUser.data.message,
          });
        }
      } catch (error) {
        this.$wait.end("loginSubmitting");

        return null;
      }
    },
    async handleWordPressSignIn() {
      try {
        const wpUser = await this.authUserFromSocial(
          {
            email: this.formFieldsSignIn.email,
            password: this.formFieldsSignIn.password,
          },
          "WORDPRESS"
        );

        if (wpUser.data.error) {
          this.$wait.end("loginSubmitting");

          this.$message({
            type: "error",
            message: wpUser.data.message,
          });
        } else {
          this.$wait.end("loginSubmitting");

          this.$emit("afterLogin");
        }
      } catch (error) {
        this.$wait.end("loginSubmitting");

        return null;
      }
    },
    onCodeChange(v) {
      this.formFieldsReset.code = v;
    },
    submitResetPasswordForm(formName) {
      if (
        !this.formFieldsReset.password ||
        this.formFieldsReset.code.length < 4
      ) {
        this.$message({
          type: "error",
          message: "Please input all fields to reset the password",
        });
      } else {
        this.setNewPassword();
      }
    },
    resendCode() {
      this.sendMailForForgottenPassword();
    },
    submitForgotPasswordForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendMailForForgottenPassword();
        } else {
          this.$message({
            type: "error",
            message: "Please check the fields before proceeding",
          });
        }
      });
    },
    sendMailForForgottenPassword() {
      return new Promise((resolve, reject) => {
        this.$wait.start("resettingPassword");
        const wpUser = this.$store
          .dispatch("authModule/sendMailForgotPassword", {
            email: this.formFieldsForgot.email,
          })
          .then((res) => {
            this.$wait.end("resettingPassword");

            this.$message({
              type: res.data.status === 200 ? "success" : "error",
              message: res.message,
            });

            this.showResetPasswordBox = true;

            resolve(res);
          })
          .catch((res) => {
            this.$wait.end("resettingPassword");

            this.$message({
              type: "error",
              message: res.data.message,
            });
          });
      }).catch((e) => {
        return reject(e);
      });
    },
    setNewPassword() {
      this.$wait.start("settingPassword");

      this.$store
        .dispatch("authModule/setPassword", {
          email: this.formFieldsForgot.email,
          password: this.formFieldsReset.password,
          code: this.formFieldsReset.code,
        })
        .then((res) => {
          this.$wait.end("settingPassword");

          this.$message({
            type: res.data.status === 200 ? "success" : "error",
            message: res.message,
          });
          this.toggleForgotPassBox();
        })
        .catch((res) => {
          this.$wait.end("settingPassword");

          this.$message({
            type: "error",
            message: res.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "login.component.scss";
</style>

<style lang="scss">
.login-content.login-oAEBqgEHZ {
  .btn-sign-in-google {
    span {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input,
  .el-divider,
  .react-code-input,
  .btn {
    max-width: 300px;
  }

  .submit {
    width: 300px;
  }

  .login-footer {
    span {
      margin-left: 6px;
    }
  }

  .signin-footer {
    max-width: 330px;
    margin: 20px auto 0;
    .el-link {
      font-size: 13px;
    }
  }

  .el-form-item__error {
    left: 40px;
  }

  .form-reset-password {
    .input.react-code-input-container {
      width: unset !important;
      margin: 0 auto;

      .react-code-input {
        display: inline-block;
        input {
          font-family: unset !important;
          font-size: 14px !important;
          border-color: #dcdfe6 !important;
        }
      }
    }

    .el-form-item__label {
      float: none;
      text-align: center;

      &:before {
        content: "" !important;
      }
    }
  }
}
</style>
